import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const setLoading: Mutation<MediaLibraryState> = (
  state,
  { essence, loading }: { essence: MediaLibraryEssence; loading: boolean }
) => {
  const currentState = state[essence]
  if (!currentState) return

  currentState.loading = loading
}

export default setLoading
