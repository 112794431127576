import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { MediaLibraryState } from '../types'
import { MediaLibraryParams } from '@/types/mediaLibrary'
import SiriusAPI from '@/api'

const fetchImagesMediaLibrary: Action<MediaLibraryState, RootState> = async (
  { state, commit },
  payload: MediaLibraryParams
) => {
  try {
    commit('setLoading', { essence: payload.essence, loading: true })

    const { items, meta } = await SiriusAPI.mediaLibrary.fetchMediaLibrary({
      essence: payload.essence,
      essenceId: payload.essenceId,
      page: payload.page,
      count: payload.count,
    })

    if (!state[payload.essence]) return

    commit('setImagesMediaLibrary', { essence: payload.essence, images: items })
    commit('setHasMore', {
      essence: payload.essence,
      total_count: meta.total_count,
    })
  } catch (error) {
    console.error(error)
  } finally {
    commit('setLoading', { essence: payload.essence, loading: false })
  }
}

export default fetchImagesMediaLibrary
