import { MutationTree } from 'vuex'
import { MediaLibraryState } from '../types'
import setEssence from './setEssence'
import setImagesMediaLibrary from './setImagesMediaLibrary'
import setImageMediaLibrary from './setImageMediaLibrary'
import setPendingRequests from './setPendingRequests'
import deletePendingRequests from './deletePendingRequests'
import deleteImageMediaLibrary from './deleteImageMediaLibrary'
import setLoading from './setLoading'
import setHasMore from './setHasMore'
import clearMediaLibrary from './clearMediaLibrary'

const mutations: MutationTree<MediaLibraryState> = {
  setEssence,
  setImagesMediaLibrary,
  setImageMediaLibrary,
  setPendingRequests,
  deletePendingRequests,
  deleteImageMediaLibrary,
  setLoading,
  setHasMore,
  clearMediaLibrary,
}

export default mutations
