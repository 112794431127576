import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'
import Vue from 'vue'

const setEssence: Mutation<MediaLibraryState> = (
  state,
  {
    essence,
    essenceId,
  }: {
    essence: MediaLibraryEssence
    essenceId: number
  }
) => {
  if (state[essence] && state[essence]?.essenceId === essenceId) return

  Vue.set(state, essence, {
    essence,
    essenceId,
    images: [],
    pendingRequests: [],
    loading: false,
    hasMore: false,
  })
}

export default setEssence
