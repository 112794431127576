import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { MediaLibraryState } from '../types'
import { MediaLibraryImage } from '@/types/mediaLibrary'
import SiriusAPI from '@/api'

const deleteImageMediaLibrary: Action<MediaLibraryState, RootState> = async (
  { state, commit },
  payload: MediaLibraryImage & { index_delete: number }
) => {
  try {
    SiriusAPI.mediaLibrary.deleteImageMediaLibrary({
      essence: payload.essence,
      essenceId: payload.essenceId,
      image_id: payload.image_id,
    })

    if (!state[payload.essence]) return

    commit('deleteImageMediaLibrary', {
      essence: payload.essence,
      index_delete: payload.index_delete,
    })
  } catch (error) {
    console.error(error)
  }
}

export default deleteImageMediaLibrary
