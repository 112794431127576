import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const clearMediaLibrary: Mutation<MediaLibraryState> = (state) => {
  Object.keys(state).forEach((key) => {
    delete state[key as MediaLibraryEssence]
  })
}

export default clearMediaLibrary
