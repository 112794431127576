import { ActionTree } from 'vuex'
import { MediaLibraryState } from '../types'
import { RootState } from '@/store/types'

import fetchImagesMediaLibrary from './fetchImagesMediaLibrary'
import addImageMediaLibrary from './addImageMediaLibrary'
import deleteImageMediaLibrary from './deleteImageMediaLibrary'

const actions: ActionTree<MediaLibraryState, RootState> = {
  fetchImagesMediaLibrary,
  addImageMediaLibrary,
  deleteImageMediaLibrary,
}

export default actions
