import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import {
  MediaLibraryEssence,
  MediaLibraryImageItem,
} from '@/types/mediaLibrary'

const setImageMediaLibrary: Mutation<MediaLibraryState> = (
  state,
  {
    essence,
    image,
  }: { essence: MediaLibraryEssence; image: MediaLibraryImageItem }
) => {
  const currentState = state[essence]
  if (!currentState) return

  currentState.images.unshift(image)
}

export default setImageMediaLibrary
