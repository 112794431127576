import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryImage } from '@/types/mediaLibrary'

const deleteImageMediaLibrary: Mutation<MediaLibraryState> = (
  state,
  payload: MediaLibraryImage & { index_delete: number }
) => {
  const currentState = state[payload.essence]
  if (!currentState) return

  currentState.images = currentState.images.filter(
    (_, imageIndex) => imageIndex !== payload.index_delete
  )
}

export default deleteImageMediaLibrary
