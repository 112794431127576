import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const setPendingRequests: Mutation<MediaLibraryState> = (
  state,
  { essence, requestId }: { essence: MediaLibraryEssence; requestId: number }
) => {
  const currentState = state[essence]
  if (!currentState) return

  currentState.pendingRequests.push(requestId)
}

export default setPendingRequests
