import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { MediaLibraryState } from '../types'

import imagesMediaLibrary from './imagesMediaLibrary'
import isLoading from './isLoading'
import pendingRequests from './pendingRequests'
import isHasMore from './isHasMore'

const getters: GetterTree<MediaLibraryState, RootState> = {
  imagesMediaLibrary,
  isLoading,
  pendingRequests,
  isHasMore,
}

export default getters
