import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { MediaLibraryState } from '../types'
import { MediaLibraryImage } from '@/types/mediaLibrary'
import SiriusAPI from '@/api'
import uniqCid from '@/helpers/uniqCid'

const addImageMediaLibrary: Action<MediaLibraryState, RootState> = async (
  { state, commit },
  { essence, essenceId, image_id }: MediaLibraryImage
) => {
  const requestId = uniqCid()
  commit('setPendingRequests', { essence, requestId })

  try {
    await SiriusAPI.mediaLibrary.addImageMediaLibrary({
      essenceId: essenceId,
      essence: essence,
      image_id: image_id,
    })

    if (!state[essence]) return

    const { items } = await SiriusAPI.mediaLibrary.fetchMediaLibrary({
      essenceId: essenceId,
      essence: essence,
      count: 3,
    })

    const imageItem = items.find(({ image_id: itemId }) => itemId === image_id)

    if (imageItem) {
      Object.freeze(imageItem)

      commit('setImageMediaLibrary', { essence, image: imageItem })
    }
  } catch (error) {
    console.error(error)
  } finally {
    commit('deletePendingRequests', { essence, requestId })
  }
}

export default addImageMediaLibrary
