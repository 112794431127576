import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const isHasMore =
  (state: MediaLibraryState) =>
  (essence: MediaLibraryEssence): boolean => {
    const essenceState = state[essence]
    return essenceState ? essenceState.hasMore : false
  }

export default isHasMore
