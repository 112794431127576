import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { MediaLibraryState } from './types'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module<MediaLibraryState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
