import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const pendingRequests =
  (state: MediaLibraryState) =>
  (essence: MediaLibraryEssence): number[] => {
    const essenceState = state[essence]
    return essenceState ? essenceState.pendingRequests : []
  }

export default pendingRequests
