import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import { MediaLibraryEssence } from '@/types/mediaLibrary'

const setHasMore: Mutation<MediaLibraryState> = (
  state,
  {
    essence,
    total_count,
  }: { essence: MediaLibraryEssence; total_count: number }
) => {
  const currentState = state[essence]
  if (!currentState) return

  currentState.hasMore = currentState.images.length < total_count
}

export default setHasMore
