import { Mutation } from 'vuex'
import { MediaLibraryState } from '../types'
import {
  MediaLibraryEssence,
  MediaLibraryImageItem,
} from '@/types/mediaLibrary'

const setImagesMediaLibrary: Mutation<MediaLibraryState> = (
  state,
  {
    essence,
    images,
  }: { essence: MediaLibraryEssence; images: MediaLibraryImageItem[] }
) => {
  const currentState = state[essence]

  if (!currentState) return

  const newImages = images.filter(
    (image) =>
      !currentState.images.some(
        (existingImage) => existingImage.id === image.id
      )
  )
  currentState.images = [...currentState.images, ...newImages]
}

export default setImagesMediaLibrary
