import { MediaLibraryState } from '../types'
import {
  MediaLibraryImageItem,
  MediaLibraryEssence,
} from '@/types/mediaLibrary'

const imagesMediaLibrary =
  (state: MediaLibraryState) =>
  (essence: MediaLibraryEssence): MediaLibraryImageItem[] => {
    const essenceState = state[essence]
    return essenceState ? essenceState.images : []
  }

export default imagesMediaLibrary
